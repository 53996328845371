<template>
  <div class="admin-theme">
    <div class="theme-wrap">
      <!-- 사이트 정보 -->
      <div class="site-section" :class="{'header-fixed': scrollY > 0}">
        <img
          src="@/assets/images/common/bul_arr_left_gray.svg"
          class="back-button"
          @click="$router.push({name: 'Dashboard'})"
        />
        <div v-if="userSite.attachFiles?.url" class="site-logo">
          <img :src="userSite.attachFiles.url" />
        </div>
        <div v-else class="site-logo site-logo-text">
          {{ userSite?.name?.slice(0, 1) }}
        </div>
        <div class="site-info">
          <div class="site-name">
            {{ userSite.name }}
          </div>
          <a :href="userSite.url" target="_blank">
            <div class="site-url">
              {{ userSite.url }}
              <img src="@/assets/images/admin/icon_link.svg" />
            </div>
          </a>
          <div class="page-title">
            테마 관리
            <tooltip
              top="30px"
              :text="`테마 관리 목록은 “라이브 테마” 와 “보관 테마” 로 구분됩니다.<br/>
              라이브 테마는 연동된 쇼핑몰 솔루션으로 최근 내보내기한 테마에 자동으로 표시됩니다.<br/>
              <a class='bold' href='${helpUrl}/board-detail?id=12&boardId=1&dtPage=1' target='_blank'>테마 메뉴 기능 안내 ></a>`"
            />
          </div>
        </div>
        <div
          class="site-info-button"
          @click="
            $router.push({
              name: 'site',
              params: {siteId: $route.params.siteId}
            })
          "
        >
          <img src="@/assets/images/admin/icon_site.svg" />사이트 정보
        </div>
      </div>
      <!-- 테마 리스트 -->
      <div class="theme-section">
        <div class="title">
          테마 관리
          <tooltip
            top="30px"
            :text="`테마 관리 목록은 “라이브 테마” 와 “보관 테마” 로 구분됩니다.<br/>
            라이브 테마는 연동된 쇼핑몰 솔루션으로 최근 내보내기한 테마에 자동으로 표시됩니다.<br/>
            <a class='bold' href='${helpUrl}/board-detail?id=12&boardId=1&dtPage=1' target='_blank'>테마 메뉴 기능 안내 ></a>`"
          />
        </div>
        <div class="top-info">
          <div class="theme-count">
            테마 생성 개수 :
            <span class="count1">{{ themeList.length }}</span
            ><span class="count2">/2</span>
          </div>
          <div
            class="add-button"
            :class="{'add-button-disable': themeList.length >= 2}"
            @click="createTheme"
          >
            <img src="@/assets/images/admin/icon_theme.svg" />테마 만들기
          </div>
        </div>
        <ul v-if="themeList.length" class="theme-list">
          <li
            v-for="(theme, index) in themeList"
            :key="index"
            :class="{
              'live-list':
                theme.userSite.userSolution !== null &&
                theme.id === theme.userSite.lastExportedUserThemeId
            }"
          >
            <img
              v-if="theme?.thumbnail?.url"
              class="theme-bg"
              :src="theme?.thumbnail?.url"
            />
            <div v-else class="theme-bg no-theme-bg">
              <img
                :src="require('@/assets/images/introduction/ico_logo_gray.svg')"
                class="no-thumbnail-logo"
              />
            </div>
            <div class="theme-info">
              <div
                v-if="
                  theme.userSite.userSolution !== null &&
                  theme.id === theme.userSite.lastExportedUserThemeId
                "
                class="theme-live"
              >
                라이브 테마
                <img src="@/assets/images/admin/icon_live.svg" />
              </div>
              <div class="more-wrap">
                <img
                  class="more-button"
                  src="@/assets/images/admin/icon_more.svg"
                  @click.stop="toggleModal(index, true)"
                />
                <div
                  v-show="modalVisible[index]"
                  class="more-modal"
                  v-click-outside:toggleModal="(index, false)"
                >
                  <img
                    class="close-button"
                    src="@/assets/images/common/ico_modal_close.svg"
                    @click="toggleModal(index, false)"
                  />
                  <ul class="more-list">
                    <li @click="linkPreview(theme.id)" class="ga_a_200">
                      <img
                        src="@/assets/images/admin/icon_preview.svg"
                      />미리보기
                    </li>
                    <li @click="userThemeCopy(index)" class="ga_a_201">
                      <img src="@/assets/images/admin/icon_copy.svg" />복사
                    </li>
                    <li
                      v-if="theme.id !== theme.userSite.lastExportedUserThemeId"
                      @click="userThemeDeleteConfirm(index)"
                    >
                      <img src="@/assets/images/admin/icon_delete.svg" />삭제
                    </li>
                    <li class="line"></li>
                    <li
                      @click="userThemeExportConfirm(index)"
                      :class="{disabled: userSite.userSolution === null}"
                      class="ga_a_202"
                    >
                      <img src="@/assets/images/admin/icon_external.svg" />테마
                      내보내기
                    </li>
                  </ul>
                </div>
              </div>
              <div class="theme-name-wrap">
                <div v-if="!inputVisible[index]" class="theme-name">
                  {{ theme.name }}
                  <img
                    src="@/assets/images/admin/icon_modify.svg"
                    @click="toggleInput(index, true)"
                  />
                </div>
                <div v-else class="theme-name-modify">
                  <input
                    type="text"
                    v-model="theme.modifyName"
                    maxlength="20"
                  />
                  <div class="save-button" @click="userThemeUpdate(index)">
                    <img src="@/assets/images/admin/icon_save.svg" />
                  </div>
                </div>
              </div>
              <div class="theme-updated-at">
                최근 수정 : {{ theme.updatedAt | date }}
              </div>
              <router-link :to="{name: 'editor', params: {themeId: theme.id}}">
                <div class="edit-button">편집하기</div>
              </router-link>
            </div>
          </li>
        </ul>
        <div v-else class="theme-empty">
          <img src="@/assets/images/admin/icon_empty.svg" />
          <p>테마가 없습니다. 회원님의 테마를 만들어주세요.</p>
        </div>
      </div>
      <div class="footer">ⓒ QPICK</div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleConfirm = createNamespacedHelpers("ModuleConfirm");
const AppHelp = process.env.VUE_APP_EXTERNAL_qpick_help_URL;

export default {
  name: "Theme",
  data() {
    return {
      themeList: [],
      userSite: [],
      scrollY: null,
      modalVisible: {},
      inputVisible: {},
      helpUrl: AppHelp
    };
  },
  created() {
    this.$EventBus.$on("alertCancel", event => {
      switch (event) {
        case "userThemeList":
          this.userThemeList();
          break;
        case "back":
          this.$router.go(-1) || window.close();
          break;
      }
    });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.userSiteRead();
    this.userThemeList();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 사이트 정보 상세
    userSiteRead() {
      const params = {
        userId: localStorage.getItem("userId"),
        siteId: this.$route.params.siteId
      };

      this.$store
        .dispatch("ModuleUserSite/actUserSiteRead", params)
        .then(response => {
          this.userSite = response.data;
        });
    },
    // 회원 테마 목록
    userThemeList() {
      const params = {
        userSiteId: this.$route.params.siteId,
        sortBy: "updatedAt-"
      };

      this.$store
        .dispatch("ModuleTheme/actUserThemeList", params)
        .then(response => {
          if (response.status === 403 || response.status === 404) {
            this.setAlert({
              isVisible: true,
              message: "접근 권한이 없습니다.",
              event: "back"
            });
            return;
          }
          const data = response.data;
          data.map(i => {
            i.modifyName = i.name;
          });
          this.themeList = data;
        });
    },
    // 더보기 팝업 toggle
    toggleModal(index, value) {
      this.modalVisible = {};
      this.$set(this.modalVisible, index, value);
    },
    // 테마 이름 수정 인풋 toggle
    toggleInput(index, value) {
      this.inputVisible = {};
      this.$set(this.inputVisible, index, value);
    },
    // 테마 이름 수정 저장
    userThemeUpdate(i) {
      const params = {
        name: this.themeList[i].modifyName,
        userThemeId: this.themeList[i].id
      };
      this.$store
        .dispatch("ModuleTheme/actUserThemeUpdate", params)
        .then(response => {
          const data = response.data;
          const themeList = [...this.themeList];
          themeList[i].name = data.name;
          this.themeList = themeList;
          this.inputVisible = {};
        });
    },
    // 테마 삭제 confirm
    userThemeDeleteConfirm(i) {
      this.setConfirm({
        isVisible: true,
        message: `삭제된 테마는 복구할 수 없습니다. 삭제하시겠습니까?`,
        messageBtn: `삭제하기`,
        event: () => this.userThemeDelete(i)
      });
    },
    // 테마 삭제
    userThemeDelete(i) {
      this.modalVisible = {};
      const params = {
        userThemeId: this.themeList[i].id
      };
      this.$store
        .dispatch("ModuleTheme/actUserThemeDelete", params)
        .then(() => {
          this.$store.dispatch("ModuleUser/actUserLog", {
            eventName: "테마 삭제",
            requestLocation: "큐픽 > 어드민 > 테마관리",
            solutionName: this.themeList[i].userSite.solution.name,
            themeId: this.themeList[i].id
          });
          this.userThemeList();
        });
    },
    // 테마 복사
    userThemeCopy(i) {
      this.modalVisible = {};
      if (this.themeList.length >= 2) {
        this.setAlert({
          isVisible: true,
          message: "베타 오픈 기간에는 테마 생성이 2개로 제한됩니다."
        });
        return;
      }
      const params = {
        userThemeId: this.themeList[i].id
      };
      this.$store
        .dispatch("ModuleTheme/actUserThemeCopy", params)
        .then(response => {
          this.$store.dispatch("ModuleUser/actUserLog", {
            eventName: "테마 복사",
            requestLocation: "큐픽 > 어드민 > 테마관리",
            solutionName: response.data.userSite.solution.name,
            themeId: response.data.id
          });
          this.userThemeList();
        });
    },
    // 테마 내보내기 confirm
    userThemeExportConfirm(i) {
      if (this.userSite.userSolution === null) {
        return;
      }
      this.setConfirm({
        isVisible: true,
        message: `이 테마를 ${this.userSite.userSolution.solutionName}에 내보내기 하시겠습니까?`,
        messageBtn: `내보내기`,
        event: () => this.userThemeExport(i)
      });
    },
    // 테마 내보내기
    userThemeExport(i) {
      this.modalVisible = {};
      const params = {
        id: this.themeList[i].id,
        userSolutionId: this.userSite.userSolutionId
      };
      this.$store
        .dispatch("ModuleTheme/actUserThemeExport", params)
        .then(response => {
          console.log(response);
          if (response.status === 200) {
            if (response.data.res === 200) {
              this.$store.dispatch("ModuleUser/actUserLog", {
                eventName: "테마 내보내기 > 성공",
                requestLocation: "큐픽 > 어드민 > 테마관리",
                solutionName: this.themeList[i].userSite.solution.name,
                themeId: this.themeList[i].id
              });
              this.setAlert({
                isVisible: true,
                message: "테마 내보내기가 완료되었습니다.",
                event: "userThemeList"
              });
            } else {
              this.$store.dispatch("ModuleUser/actUserLog", {
                eventName: "테마 내보내기 > 실패",
                requestLocation: "큐픽 > 어드민 > 테마관리",
                solutionName: this.themeList[i].userSite.solution.name,
                themeId: this.themeList[i].id,
                code: response.data.res
              });
              this.setAlert({
                isVisible: true,
                message: `테마 내보내기가 실패하였습니다.<br>(실패 사유: ${response.data.msg})`
              });
            }
          } else {
            this.setConfirm({
              isVisible: true,
              message: `테마 내보내기에 실패하였습니다. 계속 실패하는 경우 1:1문의 게시판으로 문의해주세요.`,
              messageBtn: `1:1문의하기`,
              event: () => {
                window.open(
                  `${process.env.VUE_APP_EXTERNAL_qpick_help_URL}/inquiry`,
                  "_blank"
                );
              }
            });
          }
        });
    },
    // 미리보기 연결
    linkPreview(id) {
      let routeData = this.$router.resolve({
        name: "editor",
        params: {
          themeId: id,
          preview: true
        }
      });
      window.open(routeData.href, "_blank");
    },
    // 스크롤 이벤트
    handleScroll() {
      this.scrollY = window.scrollY;
    },
    // 테마 만들기 버튼
    createTheme() {
      const siteId = this.$route.params.siteId;

      if (this.themeList.length >= 2) {
        this.setAlert({
          isVisible: true,
          message: "베타 오픈 기간에는 테마 생성이 2개로 제한됩니다."
        });
      } else {
        let routeData = this.$router.resolve({
          path: "/introduction/theme-list",
          query: {
            id: 3,
            type: "theme",
            siteId: siteId
          }
        });
        window.open(routeData.href, "_blank");
      }
    },
    ...ModuleAlert.mapMutations(["setAlert"]),
    ...ModuleConfirm.mapMutations(["setConfirm"])
  }
};
</script>

<style lang="scss">
.admin-theme {
  .theme-wrap {
    max-width: 1248px;
    margin: 0 auto;
    padding: 0 16px;

    @media screen and (max-width: 1023px) {
      padding: 0;
    }
  }

  // 사이트 정보
  .site-section {
    background: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    margin-top: 16px;
    padding: 16px 16px 16px 24px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1023px) {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
      border-radius: 0;
      margin-top: 0px;
      padding: 8px 16px 8px 24px;
    }

    .back-button {
      cursor: pointer;
    }

    .site-logo {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-left: 16px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      @media screen and (max-width: 1023px) {
        width: 40px;
        height: 40px;
      }

      img {
        max-width: 100%;
      }
    }

    .site-logo-text {
      background: #54c7a2;
      font-weight: 500;
      font-size: 24px;
      color: #fff;
    }

    .site-info {
      .site-name {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 6px;

        @media screen and (max-width: 1023px) {
          display: none;
        }
      }

      .site-url {
        font-weight: 500;
        font-size: 12px;
        color: #484948;

        @media screen and (max-width: 1023px) {
          display: none;
        }

        img {
          margin-left: 2px;
          vertical-align: -2px;
        }
      }

      .page-title {
        display: none;
        font-weight: 400;
        font-size: 16px;

        @media screen and (max-width: 1023px) {
          display: block;
          position: relative;
        }
      }
    }

    .site-info-button {
      margin-left: auto;
      width: 136px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #309574;
      border-radius: 4px;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      box-sizing: border-box;
      color: #309574;
      cursor: pointer;

      @media screen and (max-width: 1023px) {
        width: 112px;
        height: 32px;
        line-height: 32px;
        font-weight: 400;
        font-size: 14px;
      }

      img {
        margin-right: 5px;
        vertical-align: -3px;
      }
    }
  }

  // 모바일 헤더 고정
  .header-fixed {
    @media screen and (max-width: 1023px) {
      position: fixed;
      top: 0px;
      left: 0;
      width: calc(100% - 40px);
      z-index: 2;
    }
  }

  // 테마 리스트
  .theme-section {
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    margin-top: 16px;
    margin-bottom: 24px;
    padding: 24px 16px;
    min-height: 600px;

    @media screen and (max-width: 1023px) {
      box-shadow: none;
      background: none;
      border-radius: 0px;
      padding: 0px 16px;
      margin-top: 35px;
      min-height: auto;
    }

    .title {
      font-weight: 400;
      font-size: 16px;
      color: #309574;
      margin-bottom: 16px;
      position: relative;

      @media screen and (max-width: 1023px) {
        display: none;
      }
    }

    .top-info {
      display: flex;

      .theme-count {
        font-weight: 400;
        font-size: 12px;
        color: #606060;

        .count1 {
          color: #985fe8;
        }

        .count2 {
          color: #c0c0c0;
        }
      }

      .add-button {
        margin-top: -30px;
        margin-left: auto;
        width: 136px;
        height: 44px;
        background: #985fe8;
        border-radius: 4px;
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        line-height: 44px;
        cursor: pointer;

        @media screen and (max-width: 1023px) {
          margin-top: -14px;
          width: 112px;
          height: 32px;
          font-weight: 400;
          font-size: 14px;
          line-height: 32px;
        }

        img {
          margin-right: 6px;
          vertical-align: -2px;
        }
      }

      .add-button-disable {
        background: #dcdcdc;
      }
    }

    .theme-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;
      margin-bottom: -16px;

      .live-list {
        border: 2px solid #985fe8;
      }

      > li {
        width: 49.3%;
        border: 1px solid #dadada;
        border-radius: 8px;
        margin-right: 1.4%;
        display: flex;
        box-sizing: border-box;
        margin-bottom: 16px;
        background: #fff;

        @media screen and (max-width: 720px) {
          width: 100%;
          margin-right: 0;
        }

        &:nth-child(2n) {
          margin-right: 0px;
        }

        .theme-bg {
          width: 189px;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;

          @media screen and (max-width: 900px) {
            width: calc(100% - 203px);
          }

          @media screen and (max-width: 720px) {
            width: 223px;
          }

          @media screen and (max-width: 500px) {
            width: calc(100% - 203px);
          }
        }

        .no-theme-bg {
          background: #f0f0f0;
          min-height: 230px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .no-thumbnail-logo {
          width: 104px;
          text-align: center;
          @media screen and (min-width: 1024px) {
            width: 116px;
          }
        }

        .theme-info {
          width: calc(100% - 189px);
          position: relative;
          padding: 70px 16px;
          box-sizing: border-box;

          @media screen and (max-width: 900px) {
            width: 203px;
            padding-top: 56px;
          }

          @media screen and (max-width: 720px) {
            width: calc(100% - 223px);
          }

          @media screen and (max-width: 500px) {
            width: 203px;
          }

          .theme-live {
            width: 100px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            background: #985fe8;
            border-radius: 4px;
            color: #fff;
            font-weight: 500;
            font-size: 14px;
            position: absolute;
            top: 16px;
            left: 16px;

            img {
              margin-left: 2px;
            }
          }

          .more-wrap {
            .more-button {
              position: absolute;
              top: 26px;
              right: 19px;
              cursor: pointer;
              padding-bottom: 5px;
            }

            .more-modal {
              background: #ffffff;
              border: 1px solid #d8dae5;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
              border-radius: 8px;
              position: absolute;
              top: 16px;
              right: 16px;
              padding: 50px 8px 30px;
              z-index: 1;

              .close-button {
                position: absolute;
                top: 13px;
                right: 15px;
                cursor: pointer;
              }

              .more-list {
                li {
                  padding-left: 18px;
                  margin-bottom: 25px;
                  font-weight: 500;
                  font-size: 14px;
                  cursor: pointer;
                  color: #484948;

                  &:last-child {
                    margin-bottom: 0;
                  }

                  img {
                    margin-right: 10px;
                    vertical-align: -2px;
                  }
                }

                .disabled {
                  opacity: 0.3;
                }

                .line {
                  padding-left: 0px;
                  width: 168px;
                  height: 1px;
                  background: #f1f1f1;
                  cursor: none;
                }
              }
            }
          }

          .theme-name-wrap {
            margin-bottom: 12px;

            .theme-name {
              font-weight: 500;
              font-size: 16px;
              color: #484948;
              word-break: break-all;

              img {
                margin-left: 9px;
                vertical-align: -1px;
                cursor: pointer;
              }
            }

            .theme-name-modify {
              display: flex;

              input {
                height: 32px;
                border: 1px solid #d8dae5;
                border-radius: 4px;
                padding: 0 10px;
                font-size: 16px;
                outline: none;
                width: calc(100% - 62px);
              }

              .save-button {
                width: 32px;
                height: 32px;
                background: #309574;
                border-radius: 8px;
                text-align: center;
                line-height: 32px;
                cursor: pointer;
                margin-left: 8px;
              }
            }
          }

          .theme-updated-at {
            font-weight: 400;
            font-size: 12px;
            color: #8d8d8d;
          }

          .edit-button {
            position: absolute;
            bottom: 16px;
            width: calc(100% - 32px);
            height: 38px;
            line-height: 38px;
            border: 1px solid #b7b7b7;
            color: #8d8d8d;
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            cursor: pointer;

            @media screen and (max-width: 1023px) {
              height: 32px;
              line-height: 32px;
              font-weight: 400;
            }
          }
        }
      }
    }

    .theme-empty {
      background: #f7f9fc;
      border-radius: 8px;
      text-align: center;
      padding: 163px 0 445px 0;
      margin-top: 8px;

      @media screen and (max-width: 1023px) {
        padding: 163px 0 300px 0;
      }

      p {
        font-weight: 500;
        font-size: 14px;
        color: #309574;
        margin-top: 27px;
      }
    }
  }

  .footer {
    margin: 40px 0;
    color: #8d8d8d;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
  }
}
</style>
